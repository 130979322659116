<template>
  <v-container v-if="!showDontHaveService" id="packs">
    <v-row align="center" justify="center">
      <v-card elevation="0" max-width="1100" width="100%" class="transparent mb-5">
        <v-toolbar flat class="transparent">
          <h1 class="headline">{{ $t('menu.packs') }}</h1>
          <v-spacer />
          <v-btn depressed rounded color="primary" @click="showModal('nuevo')">{{ $t('packs.crear') }}</v-btn>
        </v-toolbar>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="packs"
            class="elevation-1"
            :hide-default-footer="packs.length < 10"
            :footer-props="{
              itemsPerPageText: $t('datatable.rows_per_page'),
              itemsPerPageAllText: $t('datatable.all'),
              pageText: '',
            }"
          >
            <template v-slot:item="props">
              <tr>
                <td width="20%">{{ props.item.nombre }}</td>
                <td width="40%">{{ props.item.descripcion }}</td>
                <td width="15%">{{ props.item.sesiones }}</td>
                <td width="15%">{{ props.item.precio }}{{ moneda }}</td>
                <td width="20%" class="text-right">
                  <v-btn v-tooltip="$t('common.edit')" icon color="grey" @click="editItem(props.item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn v-tooltip="$t('common.delete')" icon color="grey" @click="deleteItem(props.item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>

            <template slot="no-data"> <v-icon>mdi-alert</v-icon> {{ $t('packs.sin_packs') }} </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-row>
    <v-dialog v-model="dialog_item.dialog" width="600" overlay persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs8>
                <v-text-field
                  v-model="editedItem.nombre"
                  :rules="[rules.required]"
                  :label="$t('common.name')"
                  :outlined="outlined"
                />
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  v-model="editedItem.sesiones"
                  type="number"
                  step="1"
                  :rules="[rules.required, rules.min]"
                  :label="$t('packs.num_sesiones')"
                  :outlined="outlined"
                />
              </v-flex>
              <v-flex xs12>
                <v-textarea v-model="editedItem.descripcion" :label="$t('common.descripcion')" :outlined="outlined" />
              </v-flex>
              <v-flex xs6>
                <v-select
                  v-model="editedItem.taxType"
                  :items="taxTypes"
                  style="width: 99%"
                  :label="$t('common.taxType')"
                  auto
                  :outlined="outlined"
                  required
                  item-text="name"
                  item-value="id"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="editedItem.taxPercent"
                  :label="$t('common.taxPercent')"
                  type="number"
                  step="0.01"
                  min="0.01"
                  :outlined="outlined"
                  @input="cambiaPrecio('writing')"
                />
              </v-flex>
              <v-flex :xs6="editedItem.taxPercent > 0" :xs12="editedItem.taxPercent === 0">
                <v-text-field
                  v-model="editedItem.precio"
                  :append-icon="!es_dingdoc ? 'mdi-currency-eur' : ''"
                  type="number"
                  step="0.01"
                  :rules="[rules.required, rules.min_or_equal]"
                  :label="$t('common.precio')"
                  :outlined="outlined"
                  @keyup="cambiaPrecio('writing')"
                  @blur="cambiaPrecio('blur')"
                />
              </v-flex>
              <v-flex v-if="editedItem.precio > 0 && editedItem.taxPercent > 0" xs6>
                <v-text-field
                  v-model="editedItem.importe"
                  :label="$t('facturacion.importe')"
                  readonly="readonly"
                  :outlined="outlined"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions class="pr-2">
          <v-spacer />
          <v-btn color="grey darken-1" text @click="close"
            ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
          >
          <v-btn color="blue darken-1" dark depressed @click="save"
            ><v-icon>mdi-content-save</v-icon> {{ $t('common.save') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal Eliminar pack -->
    <span slot="widget-content">
      <v-dialog v-model="dialog_delete.dialog" dialog_delete max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('packs.eliminar_titulo') }}</span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <p>{{ $t('packs.eliminar_texto') }}</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-1" text @click.native="dialog_delete.dialog = false"
              ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
            >
            <v-btn
              color="red darken-1"
              text
              @click.native="
                dialog_delete.dialog = false;
                borrarPack();
              "
              ><v-icon>mdi-delete</v-icon> {{ $t('common.confirm') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </span>

    <!-- Snackbar para mostrar avisos -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </v-container>
  <div v-else>
    <no-service-granted view="packs" />
  </div>
</template>

<script>
import { haveEprescriptionActive } from '@/utils';
import NoServiceGranted from '@/components/common/NoServiceGranted.vue';
import { nameEnv, packsUrl, getHeader } from '../../config/config';

export default {
  components: {
    NoServiceGranted,
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      packs: [],
      dialog_item: {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
      },
      headers: [
        { text: this.$t('common.name'), value: 'nombre' },
        { text: this.$t('common.descripcion'), value: 'descripcion' },
        { text: this.$t('packs.num_sesiones'), value: 'sesiones' },
        { text: this.$t('common.precio'), value: 'precio', sortable: false },
        { text: this.$t('common.actions'), value: '', sortable: false },
      ],
      outlined: true,
      items: this.packs,
      editedIndex: -1,
      editedItem: {
        id: 0,
        nombre: '',
        descripcion: '',
        sesiones: 0,
        precio: 0,
        taxType: 0,
        taxPercent: 0,
        netAmount: 0,
        importe: 0,
      },
      defaultItem: {},
      snackbar: false,
      dialog_delete: {
        name: '',
        dialog: false,
      },
      timeout: 5000,
      mode: '',
      alerta: {
        texto: '',
        icono: '',
        color: 'info',
      },
      dataPost: {
        user_id: JSON.parse(window.localStorage.getItem('auth_ehealth'))['id'],
        accion: '',
        indice: 0,
        pack: {},
      },
      rules: {},
      tiposcaducidad: [
        { id: 1, name: '' },
        { id: 2, name: '' },
        { id: 0, name: '' },
      ],
      custom_template:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== ''
          ? true
          : false,
      es_dingdoc: nameEnv === 'dingdoc',
      moneda: nameEnv === 'dingdoc' ? '₡' : '€',
      showDontHaveService: false,
      taxTypes: [],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('packs.crear') : this.$t('packs.editar');
    },
  },
  mounted() {
    if (haveEprescriptionActive()) {
      this.showDontHaveService = true;
    } else {
      if (this.es_dingdoc) {
        this.moneda = '₡';
      }
      this.rules = {
        required: value => !!value || this.$t('common.obligatorio'),
        min: v => v > 0 || this.$tc('errors.mayor_cero', 0),
        min_or_equal: v => v >= 0 || this.$tc('errors.mayor_cero', 1),
      };
      this.defaultItem = this.editedItem;
      this.loadPacks();
    }
  },

  methods: {
    loadPacks() {
      this.$http
        .post(packsUrl, { user_id: this.authUser.id, accion: 'index' }, { headers: getHeader() })
        .then(response => {
          this.packs = response.data.packs;
          this.taxTypes = response.data.taxTypes;
        });
    },
    showModal(tipo) {
      if (tipo === 'delete') {
        this.dialog_delete.dialog = true;
      } else if (tipo === 'nuevo') {
        this.dialog_item.dialog = true;
      }
    },

    editItem(item) {
      this.editedIndex = this.packs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.importe = item.precio;
      this.editedItem.precio = item.netAmount;
      this.dialog_item.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.packs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog_delete.dialog = true;
      // confirm('Are you sure you want to delete this item?') && ;
    },

    cambiaPrecio(modo) {
      let precio = parseFloat(this.editedItem.precio);
      if ((isNaN(precio) && modo !== 'writing') || precio < 0) {
        precio = 0;
      }

      if (this.isFreemium && precio <= 0) {
        precio = 1;
      }
      this.editedItem.precio = precio;
      this.editedItem.importe =
        Math.round((parseFloat(precio) + (parseFloat(precio) * this.editedItem.taxPercent) / 100) * 100) / 100;
    },

    borrarPack() {
      this.dataPost.accion = 'eliminar';
      this.dataPost.pack = this.editedItem.id;
      this.$http.post(packsUrl, this.dataPost, { headers: getHeader() }).then(response => {
        if (response.status === 200 && response.data === '1') {
          this.packs.splice(this.editedIndex, 1);
          this.alerta.texto = this.$t('success.delete_common');
          this.alerta.color = 'success';
          this.alerta.icono = 'mdi-check';
        } else {
          this.alerta.texto = this.$t('errors.try_again');
          this.alerta.color = 'error';
          this.alerta.icono = 'mdi-alert-circle';
        }
        this.snackbar = true;
      });
    },

    cambiaDescuento() {
      if (this.editedItem.descuento < 0) {
        this.editedItem.descuento = 0;
      } else if (this.editedItem.descuento > 100) {
        this.editedItem.descuento = 100;
      }
    },

    cambiaNumUsos() {
      if (this.editedItem.n_usos < 1) {
        this.editedItem.n_usos = 1;
      }
    },

    validarPack() {
      let res = true;
      this.alerta.texto = '';
      // validamos el formulario
      if (
        this.editedItem.nombre.trim() === '' ||
        this.editedItem.sesiones === '' ||
        this.editedItem.precio === '' ||
        this.editedItem.precio === 0
      ) {
        this.alerta.texto = this.$t('errors.form_invalid');
        res = false;
        this.alerta.color = 'warning';
        this.alerta.icono = 'mdi-alert-circle';
        this.snackbar = true;
      }
      return res;
    },

    close() {
      this.dialog_item.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.validarPack()) {
        this.dataPost.accion = this.editedIndex > -1 ? 'editar' : 'nuevo';
        this.dataPost.pack = this.editedItem;
        this.dataPost.indice = this.editedIndex;
        this.$http.post(packsUrl, this.dataPost, { headers: getHeader() }).then(response => {
          if (response.status === 200 && response.data.resultado === '1') {
            this.alerta.texto = this.$t('success.save_common');
            this.alerta.color = 'success';
            this.alerta.icono = 'mdi-check';
            this.loadPacks();
          } else {
            this.alerta.texto = this.$t('errors.try_again');
            this.alerta.color = 'error';
            this.alerta.icono = 'mdi-alert-circle';
          }
          this.snackbar = true;
        });
        this.close();
      }
    },
  },
};
</script>

<style>
.titulo_diag {
  font-family: Roboto;
  font-weight: 300 !important;
  margin-bottom: -10px;
}
.acciones {
  text-align: center;
}
</style>
